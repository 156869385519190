.height-48px {
  min-height: 48px;
}

.flex-align-items-end {
  display: flex;
  align-items: flex-end;
}

.native-select-dropdown-arrow {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: url("../../Assets/DropDownArrow.svg") 96% / 4% no-repeat;
  background-size: 0.8em;
}

.native-select-dropdown-arrow:disabled {
  color: #4e4e4e;
  font-family: gilroy-medium;
}

.z-index-2 {
  z-index: 2;
}

.height-width-1em {
  height: 1em;
  width: 1em;
}

.max-height-40vh {
  max-height: 40vh;
}

.native-select.disabled {
  padding-left: 5px;
}

.native-select-border-bottom-default {
  border: none;
  border-bottom: 1px solid #757575;
}

.native-select:focus {
  outline: none;
}

.native-select:required {
  box-shadow: none;
}

.native-select-label {
  pointer-events: none;
  top: 55%;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  text-transform: uppercase;
  transform: translateY(-50%);
  color: var(--secondaryColor);
}

.native-select-label.active {
  top: 10px;
  font-size: 10px;
  color: var(--secondaryColor);
}

.native-select-label.active.disabled {
  left: 5px;
}

.adaptive-native-select-label {
  pointer-events: none;
  top: 55%;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  text-transform: uppercase;
  transform: translateY(-50%);
  color: var(--secondaryColor);
}

.adaptive-native-select-label.active {
  top: 10px;
  font-size: 10px;
  color: var(--secondaryColor);
}

.adaptive-native-select-label.active.disabled {
  left: 5px;
}

.native-select-border-bottom {
  position: relative;
  display: block;
  width: 100%;
}

.border-bottom-1px-solid-grey {
  border-bottom: 1px solid #757575;
}

.max-width-100px {
  min-width: 100px;
}

.min-width-138px {
  min-width: 138px;
}

@media screen and (min-width: 576px) {
  .adaptive-native-select-label.active {
    font-size: 15px;
  }
}
