.height-width-56px {
  height: 56px;
  width: 56px;
}

.parent-width_-68px {
  width: calc(100% - 68px);
}

.border-color-secondary-lite {
  border: 1px solid #f2f2f2;
}
